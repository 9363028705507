import MDRouter from "@/service/router";
import redirect from "@/service/redirect";
// import UserCenterSidebar from "@/components/common/UserCenterSidebar"
import { env } from "md-base-tools/env";
// eslint-disable-next-line no-unused-vars
import { get, post } from '@/utils/apiBase.js';
export default {
  name: "lottery",
  head() {
    return {
      title: "大预言家"
    };
  },
  filters: {
    time: function (str) {
      return str;
    }
  },
  components: {
    // UserCenterSidebar,
  },
  data() {
    return {
      user_center_show: false,
      isIphoneX: "",
      user_id: this.$store.state.user.userId,
      statusBarH: null,
      env,
      list: [],
      time: 0,
      day: 0,
      hour: 0,
      minute: 0,
      sec: 0,
      page: 1,
      total: 10,
      nacidx: 1,
      dialogShow: false,
      loading: false
    };
  },
  beforeMount() {},
  mounted() {
    if (!(env.isWeibo() || env.isModianIos() || env.isModianAndroid() || sessionStorage.getItem("miniProgram") || env.isWxApp())) {
      this.user_center_show = true;
    }
    this.getList();
    // eslint-disable-next-line no-undef
    graft.app({
      functionName: "onGetDeviceInfo",
      functionParams: {},
      callBack: e => {
        this.statusBarH = e.data.statusBarH;
      }
    });
  },
  beforeUnmount() {},
  methods: {
    goToDetail(id, name, idx) {
      const opt = {
        item_id: id,
        item_name: name,
        page_source: this.dialogShow ? "竞猜记录列表页" : "有奖竞猜列表页",
        module: this.dialogShow ? this.nacidx == 1 ? "我参与" + idx : "已中" + idx : "有奖竞猜" + idx
      };
      const obj = {
        ad_position: "",
        page_source: "",
        picture_id: "",
        position_source: "",
        item_id: "",
        item_name: "",
        sec_business_line: "",
        category: "",
        product_name: "",
        spu_id: "",
        sku_id: "",
        store_id: "",
        brand: "",
        url: ""
      };
      Object.assign(obj, opt);
      setTimeout(() => {
        // eslint-disable-next-line no-undef
        this.sensors.track("PositionClick", opt, () => {});
        // eslint-disable-next-line no-undef
        this.sensors.track("PositionClick", opt, () => {
          MDRouter.goProInfo(id);
        });
      });
    },
    back() {
      this.page = 1;
      this.list = [];
      this.getList();
    },
    close() {
      // eslint-disable-next-line no-undef
      graft.app({
        functionName: "closeController",
        functionParams: ""
      });
    },
    getList() {
      get("/apis/activities/prophet/list", {
        page: this.page,
        page_size: 10,
        status: 1
      }).then(({
        data
      }) => {
        if (data.list) {
          data.list.map(e => {
            // eslint-disable-next-line no-useless-escape
            let cd_time = (new Date(e.end_time.replace(/\-/g, "/")).getTime() - new Date().getTime()) / 1000;
            if (cd_time <= 0) {
              e.end_time = "已结束";
            } else {
              if (Math.floor(cd_time / (24 * 60 * 60)) > 0) {
                e.end_time = Math.floor(cd_time / (24 * 60 * 60)) + 1 + "天后结束";
              } else if (Math.floor(cd_time % (24 * 60 * 60) / (60 * 60)) > 0) {
                e.end_time = Math.floor(cd_time % (24 * 60 * 60) / (60 * 60)) + "小时后结束";
              } else if (Math.floor(cd_time % (24 * 60 * 60) % (60 * 60) / 60) > 0) {
                e.end_time = Math.floor(cd_time % (24 * 60 * 60) % (60 * 60) / 60) + "分后结束";
              } else if (Math.floor(cd_time % (24 * 60 * 60) % (60 * 60) % 60) > 0) {
                e.end_time = Math.floor(cd_time % (24 * 60 * 60) % (60 * 60) % 60) + "秒后结束";
              }
            }
          });
          this.list.push(...data.list);
          this.loading = false;
          this.total = data.total;
          this.dialogShow = false;
        }
      });
    },
    getWinlist() {
      get("/apis/activities/prophet/list", {
        page: this.page,
        page_size: 10,
        status: 3
      }).then(({
        data
      }) => {
        if (data.list) {
          data.list.map(e => {
            // eslint-disable-next-line no-useless-escape
            let cd_time = new Date(e.end_time.replace(/\-/g, "/"));
            let Mon = cd_time.getMonth();
            let Day = cd_time.getDate();
            let H = cd_time.getHours();
            let M = cd_time.getMinutes() < 10 ? "0" + cd_time.getMinutes() : cd_time.getMinutes();
            e.end_time = `${Mon + 1}月${Day}日${H}:${M}结束`;
          });
          this.list.push(...data.list);
          this.total = 0;
          this.dialogShow = true;
        }
      });
    },
    openParticipation_list() {
      if (!this.user_id) {
        redirect.to("login");
        return false;
      }
      // const opt = {
      //     page_source: "有奖竞猜列表页",
      //     element_content: "我参与的预言",
      //     item_id: "",
      //     item_name: "",
      //     guessing_id: "",
      // }
      // this.sensors.track("CommonClick", opt, () => {
      this.dialogShow = true;
      get("/apis/activities/prophet/list", {
        page: this.page,
        page_size: 10,
        status: 2
      }).then(({
        data
      }) => {
        if (data.list) {
          data.list.map(e => {
            // eslint-disable-next-line no-useless-escape
            let cd_time = new Date(e.end_time.replace(/\-/g, "/"));
            let Mon = cd_time.getMonth();
            let Day = cd_time.getDate();
            let H = cd_time.getHours();
            let M = cd_time.getMinutes() < 10 ? "0" + cd_time.getMinutes() : cd_time.getMinutes();
            e.end_time = `${Mon + 1}月${Day}日${H}:${M}结束`;
          });
          this.list.push(...data.list);
          this.total = data.total;
          this.dialogShow = true;
        }
      });
      // })
    },
    cdInit(time) {
      this.time -= 1;
      let cd_time = time;
      this.day = Math.floor(cd_time / (24 * 60 * 60));
      this.hour = Math.floor(cd_time % (24 * 60 * 60) / (60 * 60));
      this.minute = Math.floor(cd_time % (24 * 60 * 60) % (60 * 60) / 60);
      this.sec = Math.floor(cd_time % (24 * 60 * 60) % (60 * 60) % 60);
      this.hour = this.hour > 9 ? this.hour : "0" + this.hour;
      this.minute = this.minute > 9 ? this.minute : "0" + this.minute;
      this.sec = this.sec > 9 ? this.sec : "0" + this.sec;
    },
    updateTime() {
      let that = this;
      setInterval(function () {
        that.cdInit(that.time);
      }, 1000);
    },
    listScroll() {
      if (this.loading) {
        return false;
      }
      if (this.total > this.list.length) {
        let scrollHeight = this.$refs.listScroll.scrollHeight;
        let clientHeight = this.$refs.listScroll.clientHeight;
        let scrollTop = this.$refs.listScroll.scrollTop;
        if (scrollHeight - (scrollTop + clientHeight) <= 1) {
          this.timeout = null;
          if (this.timeout !== null) {
            clearTimeout(this.timeout);
          }
          if (this.dialogShow) {
            if (this.nacidx == 1) {
              this.timeout = setTimeout(() => {
                this.page++;
                this.openParticipation_list();
              }, 200);
            } else {
              this.timeout = setTimeout(() => {
                this.page++;
                this.getWinlist();
              }, 200);
            }
          } else {
            this.timeout = setTimeout(() => {
              this.page++;
              this.getList();
            }, 200);
          }
        }
      }
    }
  }
};